import React, { useState, useRef } from "react";
import { useAppContext } from "./libs/contextLib";
import { useHistory } from "react-router-dom";
import {Form, Col, Button} from 'react-bootstrap';
import { myConfig } from './config.js';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import './ForgotPassword.css';
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

export default function ForgotPassword() {
  const { isAuthenticated } = useAppContext();
  const history = useHistory();
  if (isAuthenticated) {
	  history.push("/");
  }
  localStorage.removeItem('member');
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [password, setPassword] = useState("");
  const [codeButtonText, setCodeButtonText] = useState("Send Code");
  const [changePasswordButtonText, setChangePasswordButtonText] = useState("Change Password");
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [resetDone, setResetDone] = useState(false);
  const [counter, setCounter] = useState(0);
  const codeBtn = useRef(null);
  const changePasswordBtn = useRef(null);
  
  function reCaptchaOnChange (event) {
  }
 
  function sendCodeAction (event) {
	  event.preventDefault();
	  const recaptchaValue = recaptchaRef.current.getValue();
	  clearErrors();
	  var errorFound = false;
	  if (email.length === 0) {
		  errorFound = true;
		  postError("error['email']", "Please enter this field");
	  }
	  if (recaptchaValue == null || recaptchaValue ==='') {
		  errorFound = true;
		  postError("error['recaptcha']", "Please tick the I'm not a robot");
	  }
    
	    if (!errorFound) {
	    	toggleCodeButton(true);
	        var poolData = {
	            UserPoolId: myConfig.cognito.userPoolId,
	            ClientId: myConfig.cognito.userPoolClientId
	        };
	        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
	        var userData = {
	            Username : email,
	            Pool : userPool
	        };
	        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
	        cognitoUser.forgotPassword({
	            onSuccess: function (result) {
	            	toggleCodeButton(false);
	            	closeForm1AndOpenForm2();
	            	setCounter(counter+1);
	            },
	            onFailure: function(err) {
	            	toggleCodeButton(false);
	      			postError("error['general']", err.message);
	            },
	        });
	    }
    }
  
  function handleSubmit (event) {
	  event.preventDefault();
	  clearErrors();
	  var errorFound = false;
	  if (confirmationCode.length === 0) {
		  errorFound = true;
		  postError("error['confirmationCode']", "Please enter this field");
	  }
	  if (password.length === 0) {
		  errorFound = true;
		  postError("error['password']", "Please enter this field");
	  }
	  else {
		  const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
		  if (!passwordRegex.test(password) || password.length < 8) {
			  errorFound = true;
			  postError("error['password']", "Password should be 8 chars with at least one uppercase, lowercase and number");
		  }
      }
    
    if (!errorFound) {
    	toggleChangePasswordButton(true);
        var poolData = {
            UserPoolId: myConfig.cognito.userPoolId,
            ClientId: myConfig.cognito.userPoolClientId
        };
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        var userData = {
            Username : email,
            Pool : userPool
        };
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.confirmPassword(confirmationCode, password, {
            onSuccess: function (result) {
            	setForm1(false);
            	setForm2(false);
            	setResetDone(true);
            },
            onFailure: function(err) {
            	toggleChangePasswordButton(false);
      			postError("error['general2']", err.message);
            },
        });
    }
  }
  
  function closeForm1AndOpenForm2() {
	  setForm1(false);
	  setForm2(true);
  }
  
  function closeForm2AndOpenForm1() {
	  setForm1(true);
	  setForm2(false);
  }
  
  function toggleCodeButton(makeDisable) {
	  toggleButton(codeBtn, makeDisable, "Send Code");
  }
  
  function toggleChangePasswordButton(makeDisable) {
	  toggleButton(changePasswordBtn, makeDisable, "Change Password");
  }
  
  function toggleButton(targetBtn, makeDisable, originalText) {
	  if (makeDisable) {
		  targetBtn.current.setAttribute("disabled", "disabled");
		  if (targetBtn === codeBtn) {
			  setCodeButtonText("Processing...");
		  }
		  else {
			  setChangePasswordButtonText("Processing...");
		  }
	  }
	  else {
		  if (targetBtn === codeBtn) {
			  setCodeButtonText(originalText);
		  }
		  else {
			  setChangePasswordButtonText(originalText);
		  }
		  targetBtn.current.removeAttribute("disabled");
	  }
  }
  
  function postError (id, message) {
	  document.getElementById("" + id + "").innerHTML = message;
	  document.getElementById("" + id + "").classList.add("invalid-feedback-to-display");
	  document.getElementById("" + id + "").classList.remove("invalid-feedback");
  }
  
  function clearError (id) {
	  if (document.getElementById("" + id + "") != null) {
		document.getElementById("" + id + "").innerHTML = "";
		document.getElementById("" + id + "").classList.remove("invalid-feedback-to-display");
		document.getElementById("" + id + "").classList.add("invalid-feedback");
	  }
  }
  
  function clearErrors() {
	  clearError("error['general']");
	  clearError("error['general2']");
	  clearError("error['recaptcha']");
	  clearError("error['email']");
	  clearError("error['confirmationCode']");
	  clearError("error['password']");
  }
  
  return (
    <div>
    <h2>Forgot Password</h2>
    <div style={{display: form1 ? 'block' : 'none' }}>
	<Form noValidate onSubmit={sendCodeAction}>
	<Form.Control.Feedback type="invalid" id="error['general']" />
	  <Form.Row>
		<Form.Group as={Col} controlId="formGridEmail">
	    <Form.Label>Email</Form.Label>
	    <Form.Control required type="email" placeholder="Email" name="email" value={email} onChange={e => setEmail(e.target.value)} />
	    <Form.Control.Feedback type="invalid" id="error['email']" />
	  </Form.Group>
	  </Form.Row>
	  <ReCAPTCHA className="recaptcha" sitekey={myConfig.recaptchaSitekey} ref={recaptchaRef} onChange={reCaptchaOnChange} />
	  <Form.Control.Feedback type="invalid" id="error['recaptcha']" />
	  <div className="submitBtn">
	  <Button variant="primary" type="submit" ref={codeBtn}>
	    {codeButtonText}
	  </Button>
	  </div>
	</Form>
	<a href="/login">Login</a>
    </div>
	<div style={{display: form2 ? 'block' : 'none' }}>
	An email containing a confirmation code was sent to {email}. Please enter it below.<br/><br/>
	<Form noValidate onSubmit={handleSubmit}>
	<Form.Control.Feedback type="invalid" id="error['general2']" />
	  <Form.Row>
   	     <Form.Group as={Col} controlId="formGridConfirmationCode">
	      <Form.Label>Confirmation Code</Form.Label>
	      <Form.Control required type="text" placeholder="Confirmation Code" name="confirmationCode" value={confirmationCode} onChange={e => setConfirmationCode(e.target.value)} />
	      <Form.Control.Feedback type="invalid" id="error['confirmationCode']" />
	    </Form.Group>
	    <Form.Group as={Col} controlId="formGridPassword">
	      <Form.Label>New Password</Form.Label>
	      <Form.Control required placeholder="New Password" name="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
  	      <Form.Control.Feedback type="invalid" id="error['password']" />
	    </Form.Group>
	  </Form.Row>
	  
	  <div className="submitBtn">
	  <Button variant="primary" type="submit" ref={changePasswordBtn} >
	    {changePasswordButtonText}
	  </Button>&nbsp;
	  {(() => {
        if (counter < 2) {
          return (<Button variant="primary" type="submit" onClick={closeForm2AndOpenForm1} >Enter Email Again</Button>);
        }
      })()}
	  </div>
	</Form>
	<a href="/login">Login</a>
    </div>
    <div style={{display: resetDone ? 'block' : 'none' }}>
		You can now login to your account ({email}) with the new password you have just entered above.<br/><br/>
		<Button variant="primary" href="/login">Login</Button>
	</div>
    </div>
  );
}
