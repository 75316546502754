import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from "./libs/contextLib";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {Form, Col, Row, Button, ButtonGroup, ToggleButton, Alert} from 'react-bootstrap';
import { myConfig } from './config.js';
import './ChargeMember.css';

export default function ChargeMember() {
	const { pathname, search } = useLocation();
	const { isAuthenticated, idToken } = useAppContext();
	const [totalArray, setTotalArray] = useState([]);
	const [radioChCrValue, setRadioChCrValue] = useState('1');
	const [radioValue, setRadioValue] = useState('1');
	const [totalFee, setTotalFee] = useState(0);
	const [description, setDescription] = useState("");
	const [memberIdToLoad, setMemberIdToLoad] = useState(null);
	const [times, setTimes] = useState(1);
	const [cost, setCost] = useState(0);
	const [message, setMessage] = useState(null);
	const [finalResult, setFinalResult] = useState(false);
	const [buttonText, setButtonText] = useState("Final Submit");
	const submitBtn = useRef(null);
	const history = useHistory();
	let { memberIdToLoadFromParam } = useParams();
	const radiosChCr = [
	    { name: 'Charge', value: '1' },
	    { name: 'Credit', value: '2' }
	];
	const radios = [
	    { name: 'Regular Mode', value: '1' },
	    { name: 'Sum Mode', value: '2' }
	];
	
	useEffect(() => {
		if (!isAuthenticated) {
			history.push("/login?redirect=" + pathname);
			return;
		}
		if (memberIdToLoadFromParam != null) {
			let isnum = /^\d+$/.test(memberIdToLoadFromParam);
			if (memberIdToLoadFromParam.length != 9 || !isnum) {
				history.push("/fin-tran");
				return;
			}
			else {
				setMemberIdToLoad(memberIdToLoadFromParam);
			}
		}
		if (idToken.payload["cognito:groups"].includes("Financial") == false) {
			history.push("/login");
			return;
		}
	}, []);
	
	async function loadMemberInfo(memberId, callback) {
		fetch(myConfig.apiUrl + "/members/" + memberId, {
		        method: 'GET',
	          headers: {
	              Authorization: idToken.jwtToken
	          }
		    }).then((response) => response.json())
		      .then((result) => {
		    	  if (result.success && result.data.Count > 0) {
		    		  let temp = result.data.Items[0];
		    		  callback(temp);
		      	  }
		    }).catch((error) => {
		    	callback(null);
		});
	}
	
	function postError (id, message) {
		document.getElementById("" + id + "").innerHTML = message;
		document.getElementById("" + id + "").classList.add("invalid-feedback-to-display");
		document.getElementById("" + id + "").classList.remove("invalid-feedback");
	}
	  
	function clearError (id) {
		if (document.getElementById("" + id + "") != null) {
			document.getElementById("" + id + "").innerHTML = "";
			document.getElementById("" + id + "").classList.remove("invalid-feedback-to-display");
			document.getElementById("" + id + "").classList.add("invalid-feedback");
		}
	}
	
	function round(num) {
		return Math.round((num + Number.EPSILON) * 100) / 100;
	}
	
	function handleAdd (event) {
		event.preventDefault();
		clearError("error['memberIdToLoad']");
		if (memberIdToLoad.length == 0) {
			postError("error['memberIdToLoad']", "Please input this field.");
			return;
		}
		for (var i=0; i<totalArray.length; i++) {
			if (totalArray[i]['memberId'] === memberIdToLoad) {
				postError("error['memberIdToLoad']", memberIdToLoad + " already added.");
				return;
			}
		}
		var fee = 0;
		if (radioChCrValue == 1) {
			if (radioValue == 1) {
				fee = times * cost;
			}
			else {
				var totalTimes = parseInt(times);
				for (var i=0; i<totalArray.length; i++) {
					totalTimes = totalTimes + parseInt(totalArray[i]['times']);
				}
				fee = (totalFee / totalTimes) * times;
			}
		}
		else {
			fee = cost ;
		}
		if (fee > 0) {
			loadMemberInfo(memberIdToLoad, function(memberToLoad){
				if (memberToLoad != null) {
					const newItem = {
						memberId: memberIdToLoad,
						firstName: memberToLoad.FirstName,
						lastName: memberToLoad.LastName,
						times: times,
						cost: cost,
						fee: round(fee * 1)
					};
					if (radioChCrValue == 2 || radioValue == 1) {
						var newTotalArray = totalArray;
						newTotalArray.push(newItem);
						setTotalArray(newTotalArray);
					}
					else {
						var newTotalArray = [];
						for (var i=0; i<totalArray.length; i++) {
							var timesFromArray = totalArray[i]['times'];
							var newFee = (totalFee / totalTimes) * timesFromArray;
							const temp = {
									memberId: totalArray[i]['memberId'],
									firstName: totalArray[i]['firstName'],
									lastName: totalArray[i]['lastName'],
									times: timesFromArray,
									cost: totalArray[i]['cost'],
									fee: round(newFee * 1)
							};
							newTotalArray.push(temp);
						}
						newTotalArray.push(newItem);
						setTotalArray(newTotalArray);
					}
					setMemberIdToLoad("");
					setTimes(1);
					setCost(0);
				}
			});
		}
	}
	
	function handleRemove(event, index) {
		event.preventDefault();
		if (radioChCrValue == 2 || radioValue == 1) {
			var newTotalArray = [];
			for (var i=0; i<totalArray.length; i++) {
				if (i != index) {
					newTotalArray.push(totalArray[i]);
				}
			}
			setTotalArray(newTotalArray);
		}
		else {
			var totalTimes = 0;
			for (var i=0; i<totalArray.length; i++) {
				if (i != index) {
					totalTimes = totalTimes + parseInt(totalArray[i]['times']);
				}
			}
			var newTotalArray = [];
			for (var i=0; i<totalArray.length; i++) {
				if (i != index) {
					var timesFromArray = totalArray[i]['times'];
					var newFee = (totalFee / totalTimes) * timesFromArray;
					const temp = {
							memberId: totalArray[i]['memberId'],
							firstName: totalArray[i]['firstName'],
							lastName: totalArray[i]['lastName'],
							times: timesFromArray,
							cost: totalArray[i]['cost'],
							fee: round(newFee * 1)
					};
					newTotalArray.push(temp);
				}
			}
			setTotalArray(newTotalArray);
		}
	}
	
	function handleSubmit (event) {
		event.preventDefault();
		toggleSubmitButton(true);
		setMessage(null);
		setFinalResult(false);
		clearError("error['description']");
		if (description.length == 0) {
			postError("error['description']", "Please input this field.");
			return;
		}
		fetch(myConfig.apiUrl + "/charge" , {
			method: 'POST',
			headers: {
				Authorization: idToken.jwtToken
			},
			body: JSON.stringify({'mode': radioChCrValue, 'description': description, 'totalArray': totalArray})
		}).then((response) => response.json())
			.then((result) => {
				toggleSubmitButton(false);
				if (result.success) {
					setMessage(result.data);
					setFinalResult(true);
					clearError("error['memberIdToLoad']");
	            	setTotalArray([]);
	            	setTotalFee(0);
	            	setDescription('');
				}
				else {
					setMessage(result.errorMessage);
				}
			}).catch((error) => {
				setMessage("A general error has happened.");
				toggleSubmitButton(false);
		});
	}
	
	function toggleSubmitButton(makeDisable) {
		  if (makeDisable) {
			  setButtonText("Processing...");
		  }
		  else {
			  setButtonText("Final Submit");
		  }
	}
	
	return (
		<div className="PeopleList">
    	<h2>Issue Financial Transactions</h2><br/>
    	<Alert variant={finalResult ? 'success' : 'danger' } style={{display: message != null ? 'block' : 'none' }}>{message}</Alert>
    	<Form noValidate onSubmit={handleAdd}>
		     <ButtonGroup toggle>
		        {radiosChCr.map((radio, idx) => (
		          <ToggleButton
		            key={idx}
		            type="radio"
		            variant="danger"
		            name="radio"
		            value={radio.value}
		            checked={radioChCrValue === radio.value}
		            onChange={(e) => {
		            	setRadioChCrValue(e.currentTarget.value);
		            	setRadioValue('1');
		            	setTotalArray([]);
		            	setTotalFee(0);
		            	setTimes(1);
		            	setCost(0);
		            	clearError("error['memberIdToLoad']");
		            	clearError("error['description']");
		        		setMessage(null);
		        		setFinalResult(false);
		        		}}>
		            {radio.name}
		          </ToggleButton>
		        ))}
		     </ButtonGroup><br/><br/>
		     {(() => {
			        if (radioChCrValue == 1) {
    	     return(<ButtonGroup toggle>
		        {radios.map((radio, idx) => (
		          <ToggleButton
		            key={idx}
		            type="radio"
		            variant="info"
		            name="radio"
		            value={radio.value}
		            checked={radioValue === radio.value}
		            onChange={(e) => {
		            	setRadioValue(e.currentTarget.value);
		            	setTotalArray([]);
		            	setTotalFee(0);
		            	setTimes(1);
		            	setCost(0);
		            	clearError("error['memberIdToLoad']");
		            	clearError("error['description']");
		        		setMessage(null);
		        		setFinalResult(false);
		        		}}>
		            {radio.name}
		          </ToggleButton>
		        ))}
		     </ButtonGroup>);
			        }
		     })()}
		     <div>
		     <div className="modeText">{radioChCrValue == 1 ? "This is CHARGE mode" : "This is CREDIT mode"}</div><br/>
				<Form.Group as={Row} controlId="formDescription">
					<Form.Label as={Col} sm="3">Transaction Description</Form.Label>
					<Col sm="6">
					<Form.Control required type="text" placeholder="Transaction Description" name="description" value={description} onChange={e => setDescription(e.target.value)} />
					<Form.Control.Feedback type="invalid" id="error['description']" />
					</Col>
					<Col sm="3"></Col>
				</Form.Group>
			  </div>
			  {(() => {
			        if (radioChCrValue == 1 && radioValue == 2) {
			          return (<div>
						<Form.Group as={Row} controlId="formGridTotalFee">
							<Form.Label as={Col} sm="3">Total amount to divide</Form.Label>
							<Col sm="3">
							<Form.Control required type="text" placeholder="Total Fee" name="totalFee" value={totalFee} onChange={e => setTotalFee(e.target.value.trim())} />
							<Form.Control.Feedback type="invalid" id="error['totalFee']" />
							</Col>
							<Col sm="6"></Col>
						</Form.Group>
						</div>);
			        }
			  })()}
		    <br/>
    		<Form.Row>
    		<Col sm={3}>
			<Form.Group as={Col} controlId="formGridMemberId">
				<Form.Label>MemberId</Form.Label>
				<Form.Control required type="text" placeholder="MemberId" name="memberIdToLoad" value={memberIdToLoad} onChange={e => setMemberIdToLoad(e.target.value.trim())} />
				<Form.Control.Feedback type="invalid" id="error['memberIdToLoad']" />
			</Form.Group>
			</Col>
			<Col sm={2} style={{display: radioChCrValue==1 ? 'block' : 'none' }}>
			<Form.Group as={Col} controlId="formGridTimes">
				<Form.Label>People Num.</Form.Label>
				<Form.Control required type="text" placeholder="Times" name="times" value={times} onChange={e => setTimes(e.target.value)} />
				<Form.Control.Feedback type="invalid" id="error['times']" />
			</Form.Group>
			</Col>
			<Col sm={2} style={{display: radioChCrValue==2 || radioValue==1 ? 'block' : 'none' }}>
			<Form.Group as={Col} controlId="formGridCost">
				<Form.Label>Amount</Form.Label>
				<Form.Control required type="text" placeholder="Amount" name="cost" value={cost} onChange={e => setCost(e.target.value)} disabled={radioChCrValue==1 && radioValue==2 ? 'disabled': ''} />
				<Form.Control.Feedback type="invalid" id="error['cost']" />
			</Form.Group>
			</Col>
			<Col sm={1} style={{display: radioChCrValue==1 && radioValue==1  ? 'block' : 'none' }}>
			<Form.Group>
				<Form.Label>Total</Form.Label>
				<Form.Control plaintext readOnly value={times * cost} />
			</Form.Group>
			</Col>
			<Col sm={2} className="AddButton">
				<Button variant="primary" type="submit">Add</Button>
			</Col>
			</Form.Row>
		</Form>
		<div className="PeopleList">
		  {(() => {
		        if (totalArray.length > 0) {
		          return (<div className="PeopleRow">
	        	<Form.Row>
		        	<Col sm={1}></Col>
		        	<Col sm={3}>MemberId</Col>
		        	<Col sm={4}>Name</Col>
		        	<Col sm={3}>Amount</Col>
		        	<Col sm={1}>Remove</Col>
	        	</Form.Row>
	        	</div>);
		        }
		  })()}
	      {totalArray.map((x, i) => {
	        	return(<div className="PeopleRow"><Form noValidate onSubmit={function(e) { e.preventDefault(); handleRemove(e, i); }}>
	        	<Form.Row>
		        	<Col sm={1}>{i+1}</Col>
		        	<Col sm={3}>{x.['memberId']}</Col>
		        	<Col sm={4}>{x.['firstName']} {x.['lastName']}</Col>
		        	<Col sm={3}>${x.['fee']}</Col>
		        	<Col sm={1}><Button variant="danger" type="submit">X</Button></Col>
	        	</Form.Row>
	        	</Form></div>)
	      })}
		  {(() => {
		        if (totalArray.length > 0) {
		          var totalFee = 0;
		          for(var i=0; i<totalArray.length; i++) {
		        	  totalFee += totalArray[i]['fee'];
		          }
		          return (
		        	<div>
	        		<div className="PeopleRow">
	  	        	<Form.Row>
	  		        	<Col sm={1}></Col>
	  		        	<Col sm={3}></Col>
	  		        	<Col sm={4}></Col>
	  		        	<Col sm={3}>Total: ${totalFee}</Col>
	  		        	<Col sm={1}></Col>
	  	        	</Form.Row>
	  	        	</div>
	  	        	<div className="submitBtn">
		        		<Form noValidate onSubmit={handleSubmit}>
		        			<Button variant="primary" type="submit" ref={submitBtn} disabled={buttonText=='Processing...' ? 'disabled': ''}>{buttonText}</Button>
		        		</Form>
		        	</div>
		        	</div>
		        	);
		        }
		  })()}
	    </div>
    	</div>
    );
}
