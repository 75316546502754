import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from "./libs/contextLib";
import { useHistory } from "react-router-dom";
import {Form, Col, Button, Alert} from 'react-bootstrap';
import { myConfig } from './config.js';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import './Register.css';
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

export default function Login() {
  const redirect = querystring("redirect");
  const { userHasAuthenticated, setIdToken } = useAppContext();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [password, setPassword] = useState("");
  const [buttonText, setButtonText] = useState("Login");
  const submitBtn = useRef(null);
  const submitMfaBtn = useRef(null);
  const [generalError, setGeneralError] = useState(null);
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [buttonMfaText, setButtonMfaText] = useState("Submit Code");
  const [cognitoUser, setCognitoUser] = useState(null);
  const poolData = {
	      UserPoolId: myConfig.cognito.userPoolId,
	      ClientId: myConfig.cognito.userPoolClientId
	  };
	  
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const userData = {
	    Username : email,
	    Pool : userPool
  };
  
  useEffect(() => {
	  onLoad();
  }, []);
  
  function onLoad() {
	  var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
	  var cognitoUser = userPool.getCurrentUser();
	  if (cognitoUser != null) {
		  cognitoUser.signOut();
	  }
	  userHasAuthenticated(false);
	  localStorage.removeItem('member');
  }
  
  function reCaptchaOnChange (event) {
  }
  
  function querystring(name, url = window.location.href) {
	  name = name.replace(/[[]]/g, "\\$&");
	
	  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
	  const results = regex.exec(url);
	
	  if (!results) {
	    return null;
	  }
	  if (!results[2]) {
	    return "";
	  }
	
	  return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
 
  function handleSubmit (event) {
	  event.preventDefault();
	  const recaptchaValue = recaptchaRef.current.getValue();
	  
	  setGeneralError(null);
	  clearError("error['recaptcha']");
	  clearError("error['email']");
	  clearError("error['password']");
	  clearError("error['mfaCode']");
	  
	  var errorFound = false;
	  
	  if (email.length === 0) {
		  errorFound = true;
		  postError("error['email']", "Please enter this field");
	  }
	  if (password.length === 0) {
		  errorFound = true;
		  postError("error['password']", "Please enter this field");
	  }
	  else {
		  const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
		  if (!passwordRegex.test(password) || password.length < 8) {
			  errorFound = true;
			  postError("error['password']", "Password should be 8 chars with at least one uppercase, lowercase and number");
		  }
      }
	  if (recaptchaValue == null || recaptchaValue ==='') {
		  errorFound = true;
		  postError("error['recaptcha']", "Please tick the I'm not a robot");
	  }
    
    if (!errorFound) {
    	toggleSubmitButton(true);
    	const authenticationData = {
    			Username : email,
    			Password : password,
    	};
    	const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    	const cognitoUserTemp = new AmazonCognitoIdentity.CognitoUser(userData);
    	cognitoUserTemp.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                setIdToken(result.idToken);
                userHasAuthenticated(true);
                if (redirect != null && redirect.length > 0) {
                	history.push(redirect);
                }
                else {
                	history.push("/");
                }
            },
            onFailure: function(err) {
            	toggleSubmitButton(false);
      			setGeneralError(err.message);
            },
            mfaRequired: function (result) {
            	setForm1(false);
            	setForm2(true);
            }
        });
        setCognitoUser(cognitoUserTemp);
    }
  }

  function handleMfaSubmit (event) {
	  event.preventDefault();
	  
	  setGeneralError(null);
	  clearError("error['recaptcha']");
	  clearError("error['email']");
	  clearError("error['password']");
	  clearError("error['mfaCode']");
	  
	  var errorFound = false;
	  
	  if (email.length === 0) {
		  errorFound = true;
		  postError("error['mfaCode']", "Please enter this field");
	  }
    
    if (!errorFound) {
    	toggleSubmitMfaButton(true);
        cognitoUser.sendMFACode(mfaCode, {
            onSuccess: function (result) {
                setIdToken(result.idToken);
                userHasAuthenticated(true);
                if (redirect != null && redirect.length > 0) {
                	history.push(redirect);
                }
                else {
                	history.push("/");
                }
            },
            onFailure: function(err) {

            	toggleSubmitMfaButton(false);
      			setGeneralError(err.message);
            }
        });
    }
  }
  
  function toggleSubmitButton(makeDisable) {
	  if (makeDisable) {
		  setButtonText("Processing...");
	  }
	  else {
		  setButtonText("Submit");
	  }
  }
  
  function toggleSubmitMfaButton(makeDisable) {
	  if (makeDisable) {
		  setButtonMfaText("Processing...");
	  }
	  else {
		  setButtonMfaText("Submit Code");
	  }
  }
  
  function postError (id, message) {
	  document.getElementById("" + id + "").innerHTML = message;
	  document.getElementById("" + id + "").classList.add("invalid-feedback-to-display");
	  document.getElementById("" + id + "").classList.remove("invalid-feedback");
  }
  
  function clearError (id) {
	  if (document.getElementById("" + id + "") != null) {
		document.getElementById("" + id + "").innerHTML = "";
		document.getElementById("" + id + "").classList.remove("invalid-feedback-to-display");
		document.getElementById("" + id + "").classList.add("invalid-feedback");
	  }
  }
  
  return (
    <div>
    <h2>Login</h2>
	<Alert variant="danger" style={{display: generalError != null ? 'block' : 'none' }}>{generalError}</Alert>
    <div style={{display: form1 ? 'block' : 'none' }}>
	<Form noValidate onSubmit={handleSubmit}>
	  <Form.Row>
	    <Form.Group as={Col} controlId="formGridEmail">
	      <Form.Label>Email</Form.Label>
	      <Form.Control required type="email" placeholder="Email" name="email" value={email} onChange={e => setEmail(e.target.value)} />
	      <Form.Control.Feedback type="invalid" id="error['email']" />
	    </Form.Group>
	
	    <Form.Group as={Col} controlId="formGridPassword">
	      <Form.Label>Password</Form.Label>
	      <Form.Control required placeholder="Password" name="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
  	      <Form.Control.Feedback type="invalid" id="error['password']" />
	    </Form.Group>
	  </Form.Row>
	  
	    <ReCAPTCHA className="recaptcha" sitekey={myConfig.recaptchaSitekey} ref={recaptchaRef} onChange={reCaptchaOnChange} />
	    <Form.Control.Feedback type="invalid" id="error['recaptcha']" />
	  <div className="submitBtn">
	  <Button variant="primary" type="submit" ref={submitBtn} disabled={buttonText=='Processing...' ? 'disabled': ''}>
	    {buttonText}
	  </Button>
	  	<br/><br/><a href="/forgot-password">Forgot Password</a>
	  </div>
	</Form>
	</div>
    <div style={{display: form2 ? 'block' : 'none' }}>
	<Form noValidate onSubmit={handleMfaSubmit}>
	    <Form.Group as={Col} controlId="formGridMFA">
	      <Form.Label>Mulifactor Authentication Code</Form.Label>
	      <Form.Control required type="text" placeholder="MFA Code" name="mfaCode" value={mfaCode} onChange={e => setMfaCode(e.target.value)} />
	      <Form.Control.Feedback type="invalid" id="error['mfaCode']" />
	    </Form.Group>
	  <div className="submitBtn">
	  <Button variant="primary" type="submit" ref={submitMfaBtn} disabled={buttonMfaText=='Processing...' ? 'disabled': ''}>
	    {buttonMfaText}
	  </Button>
	  	<br/><br/><a href="/forgot-password">Forgot Password</a>
	  </div>
	</Form>
	</div>
    </div>
  );
}
