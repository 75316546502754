import React from 'react';
import {Form, Col, Button} from 'react-bootstrap';
import Datetime from 'react-datetime';
import { myConfig } from './config.js';
import 'react-datetime/css/react-datetime.css';
import './Register.css';
import {Redirect} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.btn = null;
    
    this.state = {
      buttonText: 'Submit',
      firstName: '',
      lastName: '',
      dob: '',
      mobile: '',
      email: '',
      password: '',
      suburb:'',
      state: 'SA',
      postCode: '',
      othersNumber: 0,
      others: [],
      registeredMemberId: '',
      registeredFee: 0.00,
      registeredExpiry: '',
      registeredSuccess: false
    };
  }
  
  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value.trim();
    const name = target.name;
    if ((name === "mobile" || name === "postCode") && !(value === "")){
    	let isnum = /^\d+$/.test(value);
    	if (!isnum) {
    		return;
    	}
    }
    
    this.setState({
    	[name]: value
    });
  }

  handleDateTimeChange = (value) => {
	this.clearError("error['dob']");
	var now = new Date();
	if (value.isBefore(now)) {
	    this.setState({
	      dob: value.format('DD/MM/YYYY')
	    });
	}
	else {
		this.postError("error['dob']", "Please select correct date");
	}
  }
  
  handleOthersNumberChange = (event) => {
	  const target = event.target;
	  const value = target.value.trim();
	  this.setState({
		  othersNumber: value
	  });
	  var prevOthers = this.state.others;
	  if (prevOthers.length > value) {
		  var newOthers = [];
		  for (let i=0; i<value; i++) {
			  newOthers = newOthers.concat(prevOthers[i]);
		  }
		  this.setState({
			  others: newOthers
		  });
	  } else if (prevOthers.length < value) {
		  var appendToOthers = [];
		  for (let i=0; i<value - prevOthers.length; i++) {
			  appendToOthers = appendToOthers.concat({ firstName: "", lastName: "", dob: "", mobile: "" });
		  }
		  this.setState({
			  others: prevOthers.concat(appendToOthers)
		  });
	  }
  }
  
  handleOthersInputChange = (event, index) => {
	  const target = event.target;
	  const { name, value } = target;
	  if (name === "mobile" && !(value === "")){
    	let isnum = /^\d+$/.test(value);
    	if (!isnum) {
    		return;
    	}
	  }
	  var newOthers = this.state.others;
	  newOthers[index][name] = value;
	  this.setState({
		  others: newOthers
	  });
  }
 
  handleOthersDateTimeChange = (value, index) => {
	  this.clearError("error['others'][" + index + "]['dob']");
	  var now = new Date();
	  if (value.isBefore(now)) {
		  var newOthers = this.state.others;
		  newOthers[index]["dob"] = value.format('DD/MM/YYYY');
		  this.setState({
			  others: newOthers
		  });
	  }
      else {
    	  this.postError("error['others'][" + index + "]['dob']", "Please select correct date");
	  }
  }
  
  reCaptchaOnChange = (event) => {
  }
 
  handleSubmit = (event) => {
    event.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    this.clearError("error['general']");
    this.clearError("error['recaptcha']");
    Object.keys(this.state).forEach(key => {
    	if (!key.startsWith('others') && !key.startsWith('registered') && !key.startsWith('buttonText')) {
    		if (key === 'dob') {
    			if (document.getElementById("error['" + key + "']").innerHTML !== "Please select correct date") {
    				this.clearError("error['" + key + "']");
    			}
    		}
    		else {
    			this.clearError("error['" + key + "']");
    		}
    	}
    	else if (key === 'others') {
        	for (var j=0; j<this.state.othersNumber; j++) {
        		this.clearError("error['others'][" + j + "]['firstName']");
        		this.clearError("error['others'][" + j + "]['lastName']");
        		if (document.getElementById("error['others'][" + j + "]['dob']").innerHTML !== "Please select correct date") {
        			this.clearError("error['others'][" + j + "]['dob']");
        		}
        	}
    	}
    });
    var errorFound = false;
    Object.keys(this.state).forEach(key => {
        if (!key.startsWith('others') && !key.startsWith('registered') && !key.startsWith('buttonText')) {
        	if (key === 'dob') {
        		if (document.getElementById("error['" + key + "']").innerHTML === "Please select correct date") {
        			errorFound = true;
        		}
        	}
        	else {
	        	if (this.state.[key] === '') {
	        		errorFound = true;
	        		this.postError("error['" + key + "']", "Please enter this field");
	        	}
	        	else if (key === "mobile") {
	        		if (this.state.mobile.length < 10) {
	            		errorFound = true;
	        			this.postError("error['" + key + "']", "The mobile number should be 10 digits");
	        		}
	        		else if (!this.state.mobile.startsWith("04")) {
	            		errorFound = true;
	        			this.postError("error['" + key + "']", "Please enter correct mobile number starting with 04");
	        		}
	        	}
	        	else if (key === "postCode") {
	        		if (this.state.postCode.length < 4) {
	            		errorFound = true;
	        			this.postError("error['" + key + "']", "Please enter correct post code");
	        		}
	        	}
	        	else if (key === "password") {
	            	const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
	            	if (!passwordRegex.test(this.state.password) || this.state.password.length < 8) {
	            		errorFound = true;
	            		this.postError("error['" + key + "']", "Password should be 8 chars with at least one uppercase, lowercase and number");
	            	}
	            }
        	}
        }
        else if (key === 'others') {
        	for (var j=0; j<this.state.othersNumber; j++) {
            	if (this.state.others.[j].firstName === '') {
            		errorFound = true;
            		this.postError("error['others'][" + j + "]['firstName']", "Please enter this field");
            	}
            	if (this.state.others.[j].lastName === '') {
            		errorFound = true;
            		this.postError("error['others'][" + j + "]['lastName']", "Please enter this field");
            	}
            	if (document.getElementById("error['others'][" + j + "]['dob']").innerHTML === "Please select correct date") {
            		errorFound = true;
            	}
            	else if (this.state.others.[j].dob === '') {
            		errorFound = true;
            		this.postError("error['others'][" + j + "]['dob']", "Please enter this field");
            	}
        	}
        }
    });
    if (recaptchaValue == null || recaptchaValue ==='') {
    	this.postError("error['recaptcha']", "Please tick the I'm not a robot");
    	errorFound = true;
    }
    if (!errorFound) {
    	this.btn.setAttribute("disabled", "disabled");
    	this.setState({ buttonText: "Processing..." });
	    fetch(myConfig.apiUrl + "/register", {
	        method: 'POST',
	        body: JSON.stringify(this.state)
	      }).then((response) => response.json())
	      	.then((data) => {
	      		console.log(data);
	      		if (data.registerSuccess) {
	      			this.setState({ registeredSuccess: true, registeredMemberId: data.memberId, registeredFee: data.fee, registeredExpiry: data.expiry });
	      		}
	      		else {
	      			this.setState({ buttonText: "Submit" });
	      			this.btn.removeAttribute("disabled");
	      			this.postError("error['general']", data.errorMessage);
	      		}
	      	});
    }
  }
  
  postError = (id, message) => {
	  document.getElementById("" + id + "").innerHTML = message;
	  document.getElementById("" + id + "").classList.add("invalid-feedback-to-display");
	  document.getElementById("" + id + "").classList.remove("invalid-feedback");
  }
  
  clearError = (id) => {
	  if (document.getElementById("" + id + "") != null) {
		document.getElementById("" + id + "").innerHTML = "";
		document.getElementById("" + id + "").classList.remove("invalid-feedback-to-display");
		document.getElementById("" + id + "").classList.add("invalid-feedback");
	  }
  }
	  
  render() {
  if (this.state.registeredSuccess) {
	  return(
	  <Redirect to={{
          pathname: '/registered',
          state: {
        	  firstName: this.state.firstName,
        	  lastName: this.state.lastName,
        	  othersNumber: this.state.othersNumber,
        	  others: this.state.others,
        	  memberId: this.state.registeredMemberId,
        	  fee: this.state.registeredFee,
        	  expiry: this.state.registeredExpiry }
      }}
      />);
  }
  else {
  return (
    <div>
    <h2>Membership Form</h2>
    <div id="comments">
    	The membership indicates your community support and helps organising events. There is a small fee involved and is payable annually.
    	It is used towards different programs and may also be used to get discounts at certain third party events. The relevant information
    	is communicated to the members in timely manner.<br/><br/>
	    The membership fee is per family unit and is calculated based on the following:<br/>
	    1. Adults and kids 12 years old and over: $10 per annum<br/>
	    2. Kids under 12: $5 per annum<br/>
    	3. Plastic card: $2<br/><br/>
    	Please provide the following information:<br/>
    </div>
	<Form noValidate onSubmit={this.handleSubmit}>
	<Form.Control.Feedback type="invalid" id="error['general']" />
	  <Form.Row>
	    <Form.Group as={Col} controlId="formGridFirstName">
	      <Form.Label>First Name</Form.Label>
	      <Form.Control required type="text" placeholder="First Name" name="firstName" onChange={this.handleChange} />
          <Form.Control.Feedback type="invalid" id="error['firstName']" />
	    </Form.Group>
	
	    <Form.Group as={Col} controlId="formGridLastName">
	      <Form.Label>Last Name</Form.Label>
	      <Form.Control required type="text" placeholder="Last Name" name="lastName" onChange={this.handleChange} />
	      <Form.Control.Feedback type="invalid" id="error['lastName']" />
	    </Form.Group>
	  </Form.Row>
	  
	  <Form.Row>
		  <Form.Group as={Col} controlId="formGridDOB">
			  <Form.Label>Date Of Birth</Form.Label>
			  <Datetime dateFormat="DD/MM/YYYY" timeFormat={false} inputProps={{ readonly: 'true', autocomplete: 'off', id: 'dob', placeholder: 'Date Of Birth', name: 'dob', type: 'text', required: 'true'}} onChange={this.handleDateTimeChange} />
		      <Form.Control.Feedback type="invalid" id="error['dob']" />
		  </Form.Group>
			  
 	      <Form.Group as={Col} controlId="formGridMobile">
	        <Form.Label>Mobile</Form.Label>
	        <Form.Control required placeholder="Mobile" value={this.state.mobile} name="mobile" maxlength="10" onChange={this.handleChange} />
    	    <Form.Control.Feedback type="invalid" id="error['mobile']" />
	      </Form.Group>
	  </Form.Row>
	
	  <Form.Row>
	    <Form.Group as={Col} controlId="formGridEmail">
	      <Form.Label>Email</Form.Label>
	      <Form.Control required type="email" placeholder="Email" name="email" onChange={this.handleChange} />
	      <Form.Control.Feedback type="invalid" id="error['email']" />
	    </Form.Group>
	
	    <Form.Group as={Col} controlId="formGridPassword">
	      <Form.Label>Password</Form.Label>
	      <Form.Control required placeholder="Password" name="password" type="password" onChange={this.handleChange} />
  	      <Form.Control.Feedback type="invalid" id="error['password']" />
	    </Form.Group>
	  </Form.Row>
	
	  <Form.Row>
	    <Form.Group as={Col} controlId="formGridSuburb">
	      <Form.Label>Suburb</Form.Label>
	      <Form.Control name="suburb" placeholder="Suburb" onChange={this.handleChange}/>
  	      <Form.Control.Feedback type="invalid" id="error['suburb']" />
	    </Form.Group>
	
	    <Form.Group as={Col} controlId="formGridState">
	      <Form.Label>State</Form.Label>
	      <Form.Control required as="select" defaultValue="SA" name="state" onChange={this.handleChange} >
	        <option>ACT</option>
	        <option>NSW</option>
	        <option>NT</option>
	        <option>QLD</option>
	        <option>SA</option>
	        <option>TAS</option>
	        <option>VIC</option>
	        <option>WA</option>
	      </Form.Control>
	    </Form.Group>
	
	    <Form.Group as={Col} controlId="formGridPostCode">
	      <Form.Label>Post Code</Form.Label>
	      <Form.Control required name="postCode" value={this.state.postCode} placeholder="Post Code" maxlength="4" onChange={this.handleChange}/>
  	      <Form.Control.Feedback type="invalid" id="error['postCode']" />
	    </Form.Group>
	  </Form.Row>
	  
	    <Form.Group controlId="formGridOtherFamilyMembers">
	      <Form.Label>Other Family Members</Form.Label>
	      <Form.Control required as="select" defaultValue="0" name="othersNumber" onChange={this.handleOthersNumberChange} >
	        <option value="0">None</option>
	        <option>1</option>
	        <option>2</option>
	        <option>3</option>
	        <option>4</option>
	        <option>5</option>
	      </Form.Control>
	    </Form.Group>
	    
	    {this.state.others.map((x, i) => {
	    	var idFirstName = "error['others'][" + i + "]['firstName']";
	    	var idLastName = "error['others'][" + i + "]['lastName']";
	    	var idDob = "error['others'][" + i + "]['dob']";
	        return (<div>Other Family Member {i+1}
	        <Form.Row>
			    <Form.Group as={Col} controlId="formGridOthersFirstName">
			      <Form.Label>First Name</Form.Label>
			      <Form.Control required type="text" placeholder="First Name" value={x.firstName} name="firstName" onChange={e => this.handleOthersInputChange(e, i)} />
		  	      <Form.Control.Feedback type="invalid" id={idFirstName} />
			    </Form.Group>
			      
			    <Form.Group as={Col} controlId="formGridOthersLastName">
			      <Form.Label>Last Name</Form.Label>
			      <Form.Control required type="text" placeholder="Last Name" value={x.lastName} name="lastName" onChange={e => this.handleOthersInputChange(e, i)} />
			      <Form.Control.Feedback type="invalid" id={idLastName} />
			    </Form.Group>
			      </Form.Row>
			      
			      <Form.Row>
			    <Form.Group as={Col} controlId="formGridOthersDOB">
			    	<Form.Label>Date Of Birth</Form.Label>
					<Datetime dateFormat="DD/MM/YYYY" timeFormat={false} inputProps={{ readonly: 'true', autocomplete: 'off', id: 'dob', placeholder: 'Date Of Birth', name: 'dob', type: 'text'}} onChange={e => this.handleOthersDateTimeChange(e, i)} />
					<Form.Control.Feedback type="invalid" id={idDob} />
				</Form.Group>
				
		 	    <Form.Group as={Col} controlId="formGridOthersMobile">
			        <Form.Label>Mobile</Form.Label>
			        <Form.Control placeholder="Mobile" name="mobile" value={x.mobile} maxlength="10" onChange={e => this.handleOthersInputChange(e, i)} />
			    </Form.Group>
		    </Form.Row>
		    <br/>
		    </div>
	        );
	      })}
	    <ReCAPTCHA className="recaptcha" sitekey={myConfig.recaptchaSitekey} ref={recaptchaRef} onChange={this.reCaptchaOnChange} />
	    <Form.Control.Feedback type="invalid" id="error['recaptcha']" />
	  <div className="submitBtn">
	  <Button variant="primary" type="submit" ref={btn => { this.btn = btn; }} >
	    {this.state.buttonText}
	  </Button>
	  </div>
	</Form>
    </div>
  );
  }
  }
}

export default Register;
