import React, { useState, useEffect } from "react";
import { useAppContext } from "./libs/contextLib";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {Form, Col, Button, Spinner} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import './react-bootstrap-table-all.min.css';
import { timeFormatter, tableRowClassName } from './libs/memberLib';
import { myConfig } from './config.js';
import './Home.css';

export default function Transactions() {
  const { pathname, search } = useLocation();
  const [transactions, setTransactions] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [memberIdToLoad, setMemberIdToLoad] = useState(null);
  const { isAuthenticated, idToken } = useAppContext();
  let { memberIdToLoadFromParam } = useParams();
  const history = useHistory();
  
  useEffect(() => {
	  if (!isAuthenticated) {
		  history.push("/login?redirect=" + pathname);
		  return;
	  }
	  if (memberIdToLoadFromParam != null) {
		  if (idToken.payload["cognito:groups"].includes("Admin") == false) {
			  history.push("/transactions");
			  return;
		  }
		  else {
			  let isnum = /^\d+$/.test(memberIdToLoadFromParam);
			  if (memberIdToLoadFromParam.length != 9 || !isnum) {
				  history.push("/transactions");
				  return;
			  }
			  else {
				  setMemberIdToLoad(memberIdToLoadFromParam);
				  loadTransactions(memberIdToLoadFromParam);
			  }
		  }
	  }
	  else {
		  const memberId = idToken.payload["custom:memberId"];
		  if (memberId != null && memberId.length > 0) {
			  loadTransactions(memberId);
		  }
	  }
  }, [isAuthenticated]);
  
  async function loadTransactions(memberId) {
	  if (memberId == null) {
		  memberId = "";
	  }
	  setTransactions(null);
	  setNotFound(null);
	  fetch(myConfig.apiUrl + "/transactions/" + memberId, {
	      method: 'GET',
          headers: {
              Authorization: idToken.jwtToken
          }
	    }).then((response) => response.json())
	      .then((result) => {
	    	  if (result.success) {
	    		  setTransactions(result.data);
	      	  }
	    	  else {
	    		  setNotFound("ERROR");
	    	  }
	    }).catch((error) => {
	    	setNotFound("ERROR");
	 });
  }
  
  function handleSubmit(event) {
	  event.preventDefault();
	  clearError("error['memberIdToLoad']");
	  if (memberIdToLoad != null && memberIdToLoad.length > 0) {
		  if (memberIdToLoad.length < 9) {
			  postError("error['memberIdToLoad']", "MemberId is invalid");
			  return;
		  }
		  let isnum = /^\d+$/.test(memberIdToLoad);
		  if (!isnum) {
			  postError("error['memberIdToLoad']", "MemberId is invalid");
			  return;
		  }
	  }
	  loadTransactions(memberIdToLoad);
  }

  function postError (id, message) {
	  document.getElementById("" + id + "").innerHTML = message;
	  document.getElementById("" + id + "").classList.add("invalid-feedback-to-display");
	  document.getElementById("" + id + "").classList.remove("invalid-feedback");
  }
  
  function clearError (id) {
	  if (document.getElementById("" + id + "") != null) {
		document.getElementById("" + id + "").innerHTML = "";
		document.getElementById("" + id + "").classList.remove("invalid-feedback-to-display");
		document.getElementById("" + id + "").classList.add("invalid-feedback");
	  }
  }
  
  function amountFormatter(cell, row) {
	  const amount = cell >= 0 ? cell : -(cell);
	  return cell >= 0 ? "$" + amount : "-$" + amount;
  }
  
  return (<div className="UserHome">
  		  <h2>Transactions</h2>
		  {(() => {
		      if (idToken.payload["cognito:groups"].includes("Admin")) {
		        return (<Form noValidate onSubmit={handleSubmit}>
		        			<Form.Row>
		        			<Col sm="3">
		        			<Form.Group controlId="formGridMemberId">
		        				<Form.Control required type="text" placeholder="MemberId To Load" name="memberIdToLoad" maxlength="9" value={memberIdToLoad} onChange={e => setMemberIdToLoad(e.target.value)} />
		        				<Form.Control.Feedback type="invalid" id="error['memberIdToLoad']" />
		        			</Form.Group>
		        			</Col>
		        			<Col sm="1">
		        				<Button variant="primary" type="submit">Load</Button>
		        			</Col>
		        			</Form.Row>
		        			</Form>);
		      }
		  })()}
		  {transactions != null &&
			<>
			<div>
				{(() => {
					if (transactions.Count > 0) {
		        	    var options = {
		        	    	defaultSortName: 'TransactionTime',
	    	    	        defaultSortOrder: 'desc',
	    	    	        sortIndicator: true
		        	    };
						return (<div>
							Here is the list of the transactions occured in your account.<br/>
		    				<BootstrapTable data={ transactions.Items } options={ options } trClassName={tableRowClassName} pagination>
		    				  {idToken.payload["cognito:groups"].includes("Admin")&&
		    					  <TableHeaderColumn width="120" dataField='MemberId' dataSort filter={{ type: 'TextFilter', delay: 500 }}>MemberId</TableHeaderColumn>
		    				  }
			  		          <TableHeaderColumn width="130" dataField='Description' dataSort filter={{ type: 'TextFilter', delay: 500 }}>Description</TableHeaderColumn>
			  		          <TableHeaderColumn width="80" dataField='Amount' dataSort dataFormat={ amountFormatter } filter={{ type: 'TextFilter', delay: 500 }}>Amount</TableHeaderColumn>
			  		          <TableHeaderColumn width="170" dataField='TransactionTime' isKey dataSort dataFormat={ timeFormatter }>Time</TableHeaderColumn>
			  		        </BootstrapTable></div>
						);
					}
				})()}
				{transactions.Count == 0 &&
					<>
					<div>
						No transactions found.
					</div>
					</>
				}
			</div>
			</>
		  }
		  {transactions == null && notFound == null &&
			<>
			<div className="processing">
			<Spinner animation="border" role="status">
			  <span className="sr-only">Loading...</span>
			</Spinner>
			</div>
			</>
    	  }
		  {notFound != null &&
			<>
			<div className="Debt">
				The system can not get the list of transactions at the moment.
			</div>
			</>
		  }</div>
  );
}