import React, { useState, useEffect } from "react";
import { AppContext } from "./libs/contextLib";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Navbar, Nav, Card, NavDropdown} from 'react-bootstrap';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { myConfig } from './config.js';
import Register from './Register';
import Registered from './Registered';
import Contact from './Contact';
import Home from './Home';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import Payment from './Payment';
import ChargeMember from './ChargeMember';
import NfcCheckIn from './NfcCheckIn';
import ChangePassword from './ChangePassword';
import Transactions from './Transactions';
import './App.css';
import header from './img/header.jpg';

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const payload = isAuthenticated ? idToken.payload : null;
  const poolData = {
      UserPoolId: myConfig.cognito.userPoolId,
      ClientId: myConfig.cognito.userPoolClientId
  };

  var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  
  useEffect(() => {
	  onLoad();
	}, []);

  async function onLoad() {
	  try {
		var cognitoUser = userPool.getCurrentUser();
		if (cognitoUser != null) {
			cognitoUser.getSession(function(err, session) {
				if (err) {
					setIdToken(null);
					userHasAuthenticated(false);
					localStorage.removeItem('member');
				}
				else {
					setIdToken(cognitoUser.signInUserSession.idToken);
					userHasAuthenticated(true);
				}
				setIsAuthenticating(false);
			});
		}
		else {
			setIsAuthenticating(false);
		}
	  }
	  catch(e) {
		setIdToken(null);
		userHasAuthenticated(false);
		localStorage.removeItem('member');
	    setIsAuthenticating(false);
	  }
  }
  
  return (
	!isAuthenticating &&
	<div>
	<Navbar bg="dark" variant="dark" expand="md">
	    <Navbar.Brand href="/">PACANIA</Navbar.Brand>
	    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
	    <Navbar.Collapse id="responsive-navbar-nav">
	    <Nav className="mr-auto">
	      <Nav.Link href="/">Home</Nav.Link>
	      {!isAuthenticated
	    	  &&
	    	  	<>
	    	  	<Nav.Link href="/register">Register</Nav.Link>
	    	  	<Nav.Link href="/login">Login</Nav.Link>
	    	  	</>
	      }
	      <Nav.Link href="/about">About Us</Nav.Link>
	      <Nav.Link href="/contact">Contact Us</Nav.Link>
	      <Nav.Link href="https://www.pacania.com" target="_blank">سایت اطلاع رسانی پاکانیا</Nav.Link>
	      {isAuthenticated
	    	  &&
	    	  <>
	          <NavDropdown title="User Menu" id="basic-nav-dropdown">
	      		<NavDropdown.Item href="/">Member Information</NavDropdown.Item>
	      		<NavDropdown.Item href="/payment">Bank Account Information</NavDropdown.Item>
	      		<NavDropdown.Item href="/change-password">Change Password</NavDropdown.Item>
	      		<NavDropdown.Item href="/transactions">Transactions</NavDropdown.Item>
		        <NavDropdown.Divider />
		        {(() => {
		        if (payload["cognito:groups"].includes("Financial")) {
			        return(<div>
		        	<NavDropdown.Item href="/fin-tran">Issue Financial Transactions</NavDropdown.Item>
			        <NavDropdown.Divider /></div>
			        );
		        }
		        })()}
		        <NavDropdown.Item href="/login">Logout</NavDropdown.Item>
	          </NavDropdown>
	    	  </>
	      }
	    </Nav>
	    </Navbar.Collapse>
	</Navbar>
	<Card>
	    <Card.Img variant="top" src={header} />
	</Card>
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, idToken, setIdToken }}>
    <Router>
        <div className="App">
        <Switch>
          <Route exact path="/">
	        <Home />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/registered" render={(props) => <Registered {...props}/>}/>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
	        <Contact />
          </Route>
          <Route path="/login">
	        <Login />
	      </Route>
          <Route path="/forgot-password">
	        <ForgotPassword />
	      </Route>
          <Route path="/change-password">
	        <ChangePassword />
	      </Route>
          <Route path="/transactions/:memberIdToLoadFromParam">
	        <Transactions />
	      </Route>
          <Route path="/transactions">
	        <Transactions />
	      </Route>
          <Route path="/payment">
	        <Payment />
	      </Route>
          <Route path="/fin-tran/:memberIdToLoadFromParam">
	        <ChargeMember />
	      </Route>
          <Route path="/fin-tran">
	        <ChargeMember />
	      </Route>
          <Route path="/nfc-checkin/:memberIdToLoad">
	        <NfcCheckIn />
	      </Route>
          <Route path="/nfc-checkin">
	        <NfcCheckIn />
	      </Route>
        </Switch>
        </div>
        <hr/>
    	<div className="Footer">
    		Persian Academic and Cultural Association in Adelaide incorporated (PACANIA) ABN 40 818 409 916 &nbsp;&nbsp;&nbsp; Copyright &copy; {new Date().getFullYear()}
		</div>
     </Router>
     </AppContext.Provider>
     </div>
  );
}

function About() {
    return (
      <div>
      <h2>About Us</h2><br/>
      	Persian Academic and Cultural Association in Adelaide incorporated (PACANIA) was stated in 2008 and
      	officialy registered under South Australian Act of Incorporated Associations in September 2018
      	by the aim of helping persian community within the state.
      </div>
    );
}

export default App;
