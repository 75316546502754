import React, { useState, useRef } from "react";
import { useAppContext } from "./libs/contextLib";
import { useHistory } from "react-router-dom";
import {Form, Col, Button, Alert} from 'react-bootstrap';
import { myConfig } from './config.js';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import './ForgotPassword.css';

export default function ChangePassword() {
  const { isAuthenticated } = useAppContext();
  const history = useHistory();
  if (!isAuthenticated) {
	  history.push("/login");
  }
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordButtonText, setChangePasswordButtonText] = useState("Change Password");
  const changePasswordBtn = useRef(null);
  const [changePasswordDone, setChangePasswordDone] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  
  function handleSubmit (event) {
	  event.preventDefault();
	  clearErrors();
	  
	  const p1 = checkPassword(currentPassword, "currentPassword");
	  const p2 = checkPassword(newPassword, "newPassword");
	  const p3 = checkPassword(confirmPassword, "confirmPassword");
	  var errorFound = (p1 || p2 || p3) ? true : false;
	  
	  if (!errorFound && currentPassword == newPassword) {
		  errorFound = true;
		  postError("error['currentPassword']", "New password can not be the same as current password.");
		  setCurrentPassword("");
		  setNewPassword("");
		  setConfirmPassword("");
	  }
	  if (!errorFound && newPassword != confirmPassword) {
		  errorFound = true;
		  postError("error['newPassword']", "New password and confirm password are not same.");
		  setNewPassword("");
		  setConfirmPassword("");
	  }
	  
	  if (!errorFound) {
    	toggleChangePasswordButton(true);
        var poolData = {
            UserPoolId: myConfig.cognito.userPoolId,
            ClientId: myConfig.cognito.userPoolClientId
        };
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        var cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
        	toggleChangePasswordButton(false);
            cognitoUser.getSession(function (err, session) {
                if (err) {
                	toggleChangePasswordButton(false);
          			setGeneralError("A general error happened.");
                }
                else {
                	cognitoUser.changePassword(currentPassword, newPassword, function (err, result) {
                		toggleChangePasswordButton(false);
                		if (err) {
                        	if (err.message == "Incorrect username or password.") {
                        		setGeneralError("The current password is not valid.");
                        	}
                        	else {
                        		setGeneralError(err.message);
                        	}
                		}
                		else {
                        	setCurrentPassword("");
                        	setNewPassword("");
                        	setConfirmPassword("");
                        	setChangePasswordDone(true);
                		}
                	});
                }
            });
        }
    }
  }
  
  function checkPassword(password, fieldName) {
	  var errorFound = false;
	  if (password.length === 0) {
		  errorFound = true;
		  postError("error['" + fieldName + "']", "Please enter this field");
	  }
	  else {
		  const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
		  if (!passwordRegex.test(password) || password.length < 8) {
			  errorFound = true;
			  postError("error['" + fieldName + "']", "Password should be 8 chars with at least one uppercase, lowercase and number");
		  }
      }
	  return errorFound;
  }
  
  function toggleChangePasswordButton(makeDisable) {
	  toggleButton(changePasswordBtn, makeDisable, "Change Password");
  }
  
  function toggleButton(targetBtn, makeDisable, originalText) {
	  if (makeDisable) {
		  targetBtn.current.setAttribute("disabled", "disabled");
		  setChangePasswordButtonText("Processing...");
	  }
	  else {
		  setChangePasswordButtonText(originalText);
		  targetBtn.current.removeAttribute("disabled");
	  }
  }
  
  function postError (id, message) {
	  document.getElementById("" + id + "").innerHTML = message;
	  document.getElementById("" + id + "").classList.add("invalid-feedback-to-display");
	  document.getElementById("" + id + "").classList.remove("invalid-feedback");
  }
  
  function clearError (id) {
	  if (document.getElementById("" + id + "") != null) {
		document.getElementById("" + id + "").innerHTML = "";
		document.getElementById("" + id + "").classList.remove("invalid-feedback-to-display");
		document.getElementById("" + id + "").classList.add("invalid-feedback");
	  }
  }
  
  function clearErrors() {
	  setGeneralError(null);
	  clearError("error['currentPassword']");
	  clearError("error['newPassword']");
	  clearError("error['confirmPassword']");
  }
  
  return (
    <div>
    <h2>Change Password</h2>
    {changePasswordDone &&
    	<>
    	<div>
    		<Alert variant="success">Your password was changed successfully.</Alert>
    	</div>
    	</>
    }
    <div>
	<Form noValidate onSubmit={handleSubmit}>
	
		<Alert variant="danger" style={{display: generalError != null ? 'block' : 'none' }}>{generalError}</Alert>
		
	    <Form.Group as={Col} controlId="formGridCurrentPassword">
			<Form.Label>Current Password</Form.Label>
		    <Form.Control required placeholder="Current Password" name="currentPassword" type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
		    <Form.Control.Feedback type="invalid" id="error['currentPassword']" />
		</Form.Group>

	    <Form.Group as={Col} controlId="formGridNewPassword">
			<Form.Label>New Password</Form.Label>
		    <Form.Control required placeholder="New Password" name="newPassword" type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
		    <Form.Control.Feedback type="invalid" id="error['newPassword']" />
		</Form.Group>

	    <Form.Group as={Col} controlId="formGridConfirmPassword">
			<Form.Label>Confirm Password</Form.Label>
		    <Form.Control required placeholder="Confirm Password" name="confirmPassword" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
		    <Form.Control.Feedback type="invalid" id="error['confirmPassword']" />
		</Form.Group>

	  <Button variant="primary" type="submit" ref={changePasswordBtn}>
	    {changePasswordButtonText}
	  </Button>

	</Form>
	</div>
	</div>
  );
}
