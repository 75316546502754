import React from 'react';
import {Image} from 'react-bootstrap';
import email from './img/email.png';
import facebook from './img/facebook.png';
import telegram from './img/telegram.png';

class Contact extends React.Component {
  render() {
    return (
    	<div>
    	<h2>Contact Us</h2><br/>
    		You can contact us through the following channels:<br/><br/>
    		<a href="mailto:info@pacania.org.au" target="_blank"><Image src={email} width="40px" height="40px" title="Email: info@pacania.org.au" /></a>&nbsp;&nbsp;
    		<a href="https://www.facebook.com/pacania" target="_blank"><Image src={facebook} width="40px" height="40px" title="Facebook" /></a>&nbsp;&nbsp;
    		<a href="https://t.me/pacania" target="_blank"><Image src={telegram} width="40px" height="40px" title="Telegram" /></a><br/>
    		<br/>
    		Email address: <a href="mailto:info@pacania.org.au" target="_blank">info@pacania.org.au</a><br/>
    	</div>
    );
  }
}

export default Contact;