import React from "react";
import { Stage, Layer, Rect, Text } from 'react-konva';
import moment from 'moment';

export const noMemberFound = "No member found.";

export function loadCardInfo(member) {
	  var name = ["1 " + member.FirstName + " " + member.LastName];
	  const memberId = member.MemberId.toString();
	  const memberIdOnCard = memberId.substring(0,3) + " " + memberId.substring(3,6) + " " + memberId.substring(6,9);
	  for (var j=0; j<member.OthersNumber; j++) {
		  name.push([(j+2) + " " + member.Others[j].firstName + " " + member.Others[j].lastName])
	  }
	  const expiryObj = new Date(member.Expiry);
	  const expiry = (expiryObj.getMonth() <= 8 ? "0":"") + (expiryObj.getMonth()+1) + "/" + expiryObj.getFullYear()
	  var result = <Stage width={350} height={220}>
		        <Layer x={10} y={5}>
	          <Rect
	            x={1}
	            y={1}
	            width={300}
	            height={200}
	            fill="green"
	            shadowBlur={10}
	          />
	          <Text x={100} y={10} text="PACANIA" fontSize={25} />
	          <Text x={25} y={42} text={memberIdOnCard} fontSize={30} />
	          {name.map((x, i) => {
	        	  return (
	        	  <Text x={25} y={85 + (i*20)} text={x} fontSize={15} />
	        	  );
	          })}
	          <Text x={235} y={180} text={expiry} fontSize={15} />
	          </Layer>
		          </Stage>;
		          return result;
}

export function timeFormatter(cell, row) {
	return moment(cell).format('DD/MM/YYYY HH:mm:ss');
}

export function tableRowClassName(row, rowIdx) {
	return rowIdx % 2 != 0 ? "GreyRow" : "";
}
