import React, { useState, useEffect } from "react";
import { useAppContext } from "./libs/contextLib";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {Spinner} from 'react-bootstrap';
import { myConfig } from './config.js';
import { loadCardInfo, noMemberFound } from './libs/memberLib';
import MemberBalance from './libs/MemberBalance';
import './Home.css';

export default function NfcCheckIn() {
  const { pathname, search } = useLocation();
  const [memberToLoad, setMemberToLoad] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const { isAuthenticated, idToken } = useAppContext();
  const history = useHistory();
  let { memberIdToLoad } = useParams();
  useEffect(() => {
	  if (!isAuthenticated) {
		  history.push("/login?redirect=" + pathname);
		  return;
	  }
	  if (memberIdToLoad == null || memberIdToLoad.length < 9) {
		  history.push("/");
		  return;
	  }
	  let isnum = /^\d+$/.test(memberIdToLoad);
	  if (!isnum) {
		  history.push("/");
		  return;
	  }
	  if (idToken.payload["cognito:groups"].includes("Admin") == false) {
		  history.push("/login?redirect=" + pathname);
		  return;
	  }
	  loadMemberInfo(memberIdToLoad);
  }, []);
  
  async function loadMemberInfo(memberId) {
	  setMemberToLoad(null);
	  fetch(myConfig.apiUrl + "/members/" + memberId, {
	      method: 'GET',
          headers: {
              Authorization: idToken.jwtToken
          }
	    }).then((response) => response.json())
	      .then((result) => {
	    	  if (result.success && result.data.Count > 0) {
	    		  let temp = result.data.Items[0];
	    		  setMemberToLoad(temp);
	      	  }
	    	  else {
	    		  setNotFound("NOT_FOUND");
	    	  }
	    }).catch((error) => {
	    	setNotFound("NOT_FOUND");
	 });
  }
  
  return (<div className="UserHome">
		  {memberToLoad != null &&
			<>
			<div>
				<MemberBalance member={memberToLoad} loggedInMemberId={null} />
			</div>
			<div>
				{loadCardInfo(memberToLoad)}
			</div>
			<div>
				What do you want to do?<br/>
				1. <a href={"/transactions/" + memberIdToLoad}>Load Transactions</a><br/>
				2. <a href={"/fin-tran/" + memberIdToLoad}>Issue Finanfial Transactions</a><br/>
			</div>
			</>
		  }
		  {memberToLoad == null && notFound == null &&
			<>
			<div className="processing">
			<Spinner animation="border" role="status">
			  <span className="sr-only">Loading...</span>
			</Spinner>
			</div>
			</>
    	  }
		  {notFound != null &&
			<>
			<div className="Debt">
				{noMemberFound}
			</div>
			</>
		  }</div>
  );
}