import React, { useState, useEffect } from "react";
import { useAppContext } from "./libs/contextLib";
import {Form, Col, Button, Spinner} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import './react-bootstrap-table-all.min.css';
import { myConfig } from './config.js';
import './Home.css';
import { loadCardInfo, noMemberFound, timeFormatter } from './libs/memberLib';
import MemberBalance from './libs/MemberBalance';

export default function Home() {
  const [members, setMembers] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [memberIdToLoad, setMemberIdToLoad] = useState(null);
  const { isAuthenticated, idToken } = useAppContext();
  const payload = isAuthenticated ? idToken.payload : null;

  function renderNotAuthenticated() {
	  return(
    	  <div>
    	  <h2>Persian Academic and Cultural Association in Adelaide incorporated</h2><hr/>
		      Together we can bring more to our community. You may register to become a member. The membership
		      means supporting your society. It may also provide benefits to your family.<br/>
		      You will get your membership card as soon as you filled out the membership form.
		      <br/>
		      <br/>
		      <Button variant="primary" href="/register">Become A Member</Button>
		      &nbsp;&nbsp;
		      <Button variant="primary" href="/login">Login</Button>
    	  </div>
	  );
  }
  
  async function loadMemberInfo(memberId) {
	  if (memberId == null) {
		  memberId = "";
	  }
	  setMembers(null);
	  setNotFound(null);
	  
	  fetch(myConfig.apiUrl + "/members/" + memberId, {
	      method: 'GET',
          headers: {
              Authorization: idToken.jwtToken
          }
	    }).then((response) => response.json())
	      .then((result) => {
	    	  if (result.success && result.data.Count > 0) {
	    		  setMembers(result.data.Items);
	    		  if (result.data.Count == 1) {
		    		  localStorage.setItem('member', result.data.Items[0]);
	    		  }
	      	  }
	    	  else {
	    		  setNotFound("NOT_FOUND");
	    	  }
	    }).catch((error) => {
	    	setNotFound("NOT_FOUND");
	 });
  }
  
  function handleSubmit(event) {
	  event.preventDefault();
	  clearError("error['memberIdToLoad']");
	  if (memberIdToLoad != null && memberIdToLoad.length > 0) {
		  if (memberIdToLoad.length < 9) {
			  postError("error['memberIdToLoad']", "MemberId is invalid");
			  return;
		  }
		  let isnum = /^\d+$/.test(memberIdToLoad);
		  if (!isnum) {
			  postError("error['memberIdToLoad']", "MemberId is invalid");
			  return;
		  }
	  }
	  loadMemberInfo(memberIdToLoad);
  }
  
  function loadFromLink(event, memberId) {
	  event.preventDefault();
	  setMemberIdToLoad(memberId);
	  loadMemberInfo(memberId);
  }
  
  function postError (id, message) {
	  document.getElementById("" + id + "").innerHTML = message;
	  document.getElementById("" + id + "").classList.add("invalid-feedback-to-display");
	  document.getElementById("" + id + "").classList.remove("invalid-feedback");
  }
  
  function clearError (id) {
	  if (document.getElementById("" + id + "") != null) {
		document.getElementById("" + id + "").innerHTML = "";
		document.getElementById("" + id + "").classList.remove("invalid-feedback-to-display");
		document.getElementById("" + id + "").classList.add("invalid-feedback");
	  }
  }
  
  useEffect(() => {
	  if (payload != null) {
		  const memberId = payload["custom:memberId"];
		  if (memberId != null && memberId.length > 0) {
			  loadMemberInfo(memberId);
		  }
	  }
  }, [isAuthenticated]);
  
  function tableRowClassName(row, rowIdx) {
	  return (row.Fee - row.PaidFee > 0) ? "RedRow": (rowIdx % 2 != 0 ? "GreyRow" : "");
  }
  
  function memberIdFormatter(cell, row) {
	  return (<a href="#" onClick={e => loadFromLink(e, cell)}>{cell}</a>);
  }
  
  function othersNumberFormatter(cell, row) {
	  return cell != null ? cell : 0; 
  }
  
  function renderAuthenticated() {
	  return(
		    <div className="UserHome">
				<h2>Welcome back <b>{payload.name}</b></h2><br/>
				You can use the "User Menu" from the top to access different sections of this web site.<br/><br/>
				  {(() => {
			        if (payload["cognito:groups"].includes("Admin")) {
			          return (<Form noValidate onSubmit={handleSubmit}>
			          			<Form.Row>
			          			<Col sm="3">
			          			<Form.Group controlId="formGridMemberId">
			          				<Form.Control required type="text" placeholder="MemberId To Load" name="memberIdToLoad" maxlength="9" value={memberIdToLoad} onChange={e => setMemberIdToLoad(e.target.value)} />
			          				<Form.Control.Feedback type="invalid" id="error['memberIdToLoad']" />
			          			</Form.Group>
			          			</Col>
			          			<Col sm="1">
			          				<Button variant="primary" type="submit">Load</Button>
			          			</Col>
			          			</Form.Row>
			          			</Form>);
			        }
				  })()}
				  {(() => {
			        if (members != null) {
			        	if (members.length == 1) {
				        	return (
								<>
								The card information are as below:<br/>
								<div>
									<MemberBalance member={members[0]} loggedInMemberId={payload["custom:memberId"]} />
								</div>
								<div>
									{loadCardInfo(members[0])}
								</div>
								{payload["cognito:groups"].includes("Admin")&&
									<div>
										What do you want to do?<br/>
										1. <a href={"/transactions/" + members[0].MemberId}>Load Transactions</a><br/>
										{payload["cognito:groups"].includes("Financial")&&
										<>
										2. <a href={"/fin-tran/" + members[0].MemberId}>Issue Finanfial Transactions</a><br/>
										</>
										}
									</div>
								}
								</>
							);
			        	}
			        	else {
			        	    var options = {
			        	    	defaultSortName: 'MemberId',
	        	    	        defaultSortOrder: 'asc',
	        	    	        sortIndicator: true
			        	    };
			        		return(
		        				<BootstrapTable data={ members } options={ options } trClassName={tableRowClassName} pagination>
		        		          <TableHeaderColumn width="120" dataField='MemberId' isKey dataSort dataFormat={ memberIdFormatter }>MemberId</TableHeaderColumn>
		        		          <TableHeaderColumn width="130" dataField='FirstName' dataSort filter={{ type: 'TextFilter', delay: 500 }}>First Name</TableHeaderColumn>
		        		          <TableHeaderColumn width="170" dataField='LastName' dataSort filter={{ type: 'TextFilter', delay: 500 }}>Last Name</TableHeaderColumn>
		        		          <TableHeaderColumn width="80" dataField='Fee' dataSort>Fee</TableHeaderColumn>
		        		          <TableHeaderColumn width="80" dataField='PaidFee' dataSort>Paid Fee</TableHeaderColumn>
		        		          <TableHeaderColumn width="170" dataField='FeeAlert' dataFormat={ timeFormatter }>Last Fee Alert</TableHeaderColumn>
		        		          <TableHeaderColumn width="80" dataField='OthersNumber' dataFormat={ othersNumberFormatter }>Others</TableHeaderColumn>
		        		        </BootstrapTable>
			        		);
			        	}
			        }
			    })()}
				{members == null && notFound == null &&
					<>
					<div className="processing">
					<Spinner animation="border" role="status">
					  <span className="sr-only">Loading...</span>
					</Spinner>
					</div>
					</>
				}
				{
					notFound != null &&
					<>
					<div className="Debt">
						{noMemberFound}
					</div>
					</>
				}
			</div>
	  );
  }
  return (
    <div>
      {isAuthenticated ? renderAuthenticated() : renderNotAuthenticated()}
    </div>
  );
}
