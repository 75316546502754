import React from "react";
import { useAppContext } from "./libs/contextLib";
import { useHistory } from "react-router-dom";
import './Payment.css';

export default function Payment() {
  const { isAuthenticated, idToken } = useAppContext();
  const history = useHistory();
  if (!isAuthenticated) {
	  history.push("/login");
  }
  const payload = isAuthenticated ? idToken.payload : null;
  var memberId = "";
  if (payload != null) {
	  memberId = payload["custom:memberId"];
  }
  return (
    <div className="BankAccInfo">
    <h2>Bank Account Information</h2>
	Bank Name: CommonWealth Bank<br/>
	Account Name: PACANIA<br/>
	BSB: <b>065004</b><br/>
	Account number: <b>11133386</b><br/>
    Reference: <b>Member {memberId}</b><br/>
    <div id="note">Please make sure you put the above reference in all your bank transfers. Also, allow 24 to 48 hours for bank transfers to reflect in your balance.</div>
    <br/>
    </div>
  );
}
