import React from "react";
import './MemberBalance.css';

export default function MemberBalance(props) {
	var member = props.member;
	var loggedInMemberId = props.loggedInMemberId;
	var paidFee = parseFloat(member.PaidFee);
	var fee = parseFloat(member.Fee);
	var balance = (paidFee - fee).toFixed(2);
	return (<div className="MemberBalance">
		{(() => {
			if (loggedInMemberId != null && loggedInMemberId == member.MemberId) {
				if (balance < 0) {
					return (<div className="Debt">You need to pay ${-balance} to PACANIA bank account. Please refer to your <a href='/transactions'>transaction history.</a><br/>Please check the bank account information from User Menu menu on the top for more information. Also it may take 24 to 48 hours for bank transfers to reflect in your balance.</div>);
				}
				else {
					return (<div className="NoDebt">Account Balance: ${balance}</div>);
				}
			}
			else {
				if (balance < 0) {
					return (<div className="Debt">Account Balance: -${-balance}</div>);
				}
				else {
					return (<div className="NoDebt">Account Balance: ${balance}</div>);
				}
			}
		})()}
	</div>);
}