import React from 'react';
import {Redirect} from "react-router-dom";
import { Stage, Layer, Rect, Text } from 'react-konva';
import './Registered.css';

class Registered extends React.Component {
	
	render() {
	if (this.props.location == null || this.props.location.state == null || this.props.location.state.memberId === '') {
		return(<Redirect to="/register" />);
	}
	else {
		var name = ["1 " + this.props.location.state.firstName + " " + this.props.location.state.lastName];
		const memberId = this.props.location.state.memberId.toString();
		const memberIdOnCard = memberId.substring(0,3) + " " + memberId.substring(3,6) + " " + memberId.substring(6,9);
		for (var j=0; j<this.props.location.state.othersNumber; j++) {
			name.push([(j+2) + " " + this.props.location.state.others[j].firstName + " " + this.props.location.state.others[j].lastName])
		}
		return (
		    <div className="Registered">
		    <h2>PACANIA Membership Registeration Successful</h2>
	    	<b>{this.props.location.state.firstName}</b>, thanks for becoming our member.<br/>
	    	Please find below your membership card. It will be activated once you pay <b>${this.props.location.state.fee}</b> to the following account:<br/><br/>
	    		Bank Name: CommonWealth Bank<br/>
	    		Account Name: PACANIA<br/>
	    		BSB: 065004<br/>
	    		Account number: 11133386<br/>
	    		Reference: Member {memberId}<br/>
	    		<b>Please make sure you put the above reference in the transfer.</b><br/><br/>

		      <Stage width={350} height={220}>
		        <Layer x={10} y={5}>
	          <Rect
	            x={1}
	            y={1}
	            width={300}
	            height={200}
	            fill="green"
	            shadowBlur={10}
	          />
	          <Text x={100} y={10} text="PACANIA" fontSize={25} />
	          <Text x={25} y={42} text={memberIdOnCard} fontSize={30} />
	          {name.map((x, i) => {
	        	  return (
	        	  <Text x={25} y={85 + (i*20)} text={x} fontSize={15} />
	        	  );
	          })}
	          <Text x={235} y={180} text={this.props.location.state.expiry} fontSize={15} />
	          </Layer>
		          </Stage>
		    </div>
			);
		}
    }
}

export default Registered;
